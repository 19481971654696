import React from 'react'

interface InfoBoxProps {
    transitioning: boolean
    setTransitioning: (transitioning: boolean) => void
  }
  
const InfoBox: React.FC<InfoBoxProps> = ({ transitioning, setTransitioning }) => {
    return <>

        <picture>
            <source srcSet="/big_logo.webp" type="image/webp" />
            <img 
                src="/big_logo.png" 
                alt="Rock Paper Scissors Infinity" 
                style={{ width: '30vw' }} 
            />
        </picture>

        <p>Have you ever wanted to play rock paper scissors with literally anything? Now you can!</p>

        <p>Add new items and let the AI decide who wins. The options are infinite!</p>

        
        <div> Code & Design by Nathan Clark </div>

        <div> LLM Backend Powered by OpenAI </div>
        
        <div> Splash imagery generated by DALL·E 2 </div>

            
    </>
}
  

export default InfoBox;