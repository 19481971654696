import React, { useEffect, useCallback, useRef } from 'react';
import { Item, ItemRef } from './types'

interface ItemSelectionProps {
  items: Item[];
  handleSelection: (item: ItemRef) => void;
  currentUser: 'A' | 'B';
  userASelection: ItemRef | null;
  userBSelection: ItemRef | null;
  transitioning: boolean;
  handleConfirm: () => void;
}

const ItemSelection: React.FC<ItemSelectionProps> = ({
  items,
  handleSelection,
  currentUser,
  userASelection,
  userBSelection,
  transitioning,
  handleConfirm
}) => {
  const emojiRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Enter' && !document.activeElement?.classList.contains('add-item-input')) {
      if (userASelection || userBSelection) {
        handleConfirm();
      }
    }
  }, [userASelection, userBSelection, handleConfirm]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
        event.preventDefault();
        const items = document.querySelectorAll('.item');
        const selectedItem = document.querySelector('.item.selected');
        let currentIndex = Array.from(items).indexOf(selectedItem!);
        if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
          currentIndex = currentIndex > 0 ? currentIndex - 1 : items.length - 1;
        } else if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
          currentIndex = currentIndex < items.length - 1 ? currentIndex + 1 : 0;
        }
        (items[currentIndex] as HTMLElement).click();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [items, handleSelection]);

  useEffect(() => {
    const adjustFontSize = () => {
      emojiRefs.current.forEach((ref) => {
        if (ref) {
          const parentWidth = ref.parentElement?.clientWidth || 0;
          const refWidth = ref.clientWidth;
          const scale = (0.8 * parentWidth) / refWidth;
          ref.style.fontSize = `${Math.min(scale * 100, 100)}%`;
        }
      });
    };

    adjustFontSize();
    window.addEventListener('resize', adjustFontSize);
    return () => window.removeEventListener('resize', adjustFontSize);
  }, [items]);

  return (
    <div className={`item-selection ${currentUser === 'A' ? 'user-a' : 'user-b'} ${transitioning ? 'fade-out' : 'fade-in'}`}>
      <h3 className="user-selection">Player {currentUser}</h3>
      <ul>
        {items.map((item, index) => (
          <li
            key={index}
            onClick={transitioning ? 
              () => {} :
              () => handleSelection(item.name)}
            className={`item ${transitioning && 'disabled'} ${currentUser === 'A' && userASelection === item.name ? 'selected' : ''} ${currentUser === 'B' && userBSelection === item.name ? 'selected' : ''}`}
          >
            {item.emoji ?
              <div className="emoji-container" ref={(el) => (emojiRefs.current[index] = el)}>
                <div className="emoji">{item.emoji}</div>
              </div> :
              <div className="loading-circle"/>
            }
            <span className="item-name">{item.name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ItemSelection;
