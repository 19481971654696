import React, { useState, useEffect } from 'react';
import './EmojiBg.css';
import EmojiStaggered from './EmojiStaggered';
import EmojiGrid from './EmojiGrid';
import EmojiFloat from './EmojiFloat';
// import EmojiRotateGrid from './EmojiRotateGrid';
// import EmojiCrossBounce from './EmojiCrossBounce';
// import EmojiBezierCurve from './EmojiBezierCurve';

const animations = [
  'staggered',
  'grid',
  'float',
  // 'rotateGrid',
  // 'crossBounce',
  // 'bezierCurve'
];

type Props = { emoji: string };

const EmojiBg: React.FC<Props> = (props: Props) => {
  const [animation, setAnimation] = useState<string>('staggered');

  useEffect(() => {
    const randomAnimation = animations[Math.floor(Math.random() * animations.length)];
    setAnimation(randomAnimation);
  }, []);

  return (
    <div className="emoji-background">
      {animation === 'staggered' && <EmojiStaggered emoji={props.emoji} />}
      {animation === 'grid' && <EmojiGrid emoji={props.emoji} />}
      {animation === 'float' && <EmojiFloat emoji={props.emoji} />}
      {/* {animation === 'rotateGrid' && <EmojiRotateGrid emoji={props.emoji} />}
      {animation === 'crossBounce' && <EmojiCrossBounce emoji={props.emoji} />}
      {animation === 'bezierCurve' && <EmojiBezierCurve emoji={props.emoji} />} */}
    </div>
  );
};

export default EmojiBg;
