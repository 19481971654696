import React, { useEffect, useState } from 'react';
import styles from './Title.module.css';
import { menus } from './constants'
import EmojiBackground from './EmojiBg/RandomScroll'
import { useNavigate } from 'react-router-dom';

const Title: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const navigate = useNavigate();

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 600);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsVisible(true);
  //   }, 1000)})
  

  return (
    <>
      {Math.random() > 0.5 ? <EmojiBackground/> : <> 
      <div className={styles.bg + ' ' + styles.bgUniverse} />
      <div className={styles.bg + ' ' + styles.bgRockLayer} />
      <div className={styles.bg + ' ' + styles.bgPaperLayer} />
      <div className={styles.bg + ' ' + styles.bgScissorLayer} />
      </>}
      <div className={`${styles.menu} ${isMobile ? styles.mobile : ''}`}>
        <div className={styles.bg + ' ' + styles.homepageLogo} />
        <>
          {menus.map((menu) => (menu.enabled ?
            <button
            //   key={menu.id}
              onClick={() => navigate(menu.id)}
              disabled={!menu.enabled}
            >
              {menu.emoji} {menu.name} {menu.emoji}
            </button> :
            <></>
          ))}
        </>
      </div>
    </>
  );
};

export default Title;
