import React from 'react';
import './EmojiFloat.css';

type Props = { emoji: string };

const EmojiFloat: React.FC<Props> = ({ emoji }) => {
  const randomValue = (min: number, max: number) => Math.random() * (max - min) + min;
  const randomTranslate = () => ({
    x: `${randomValue(-50, 50)}vw`,
    y: `${randomValue(-50, 50)}vh`
  });

  const emojis = Array.from({ length: 50 }).map((_, index) => ({
    id: index,
    top: `${randomValue(0, 100)}vh`,
    left: `${randomValue(0, 100)}vw`,
    duration: `${randomValue(8, 12)}s`,
    translate: randomTranslate()
  }));

  return (
    <div className="float-container">
      {emojis.map(({ id, top, left, duration, translate }) => (
        <span
          key={id}
          className="float-emoji"
          style={{
            top,
            left,
            animationDuration: duration,
            '--translate-x': translate.x,
            '--translate-y': translate.y,
          } as React.CSSProperties}
        >
          {emoji}
        </span>
      ))}
    </div>
  );
};

export default EmojiFloat;
