import React from 'react';
import './EmojiStaggered.css';

type Props = { emoji: string };

const EmojiStaggered: React.FC<Props> = ({ emoji }) => (
  <>
    <span className="emoji-animated emoji-animated2">{emoji}</span>
    <span className="emoji-animated emoji-animated1">{emoji}</span>
    <span className="emoji-animated emoji-animated0">{emoji}</span>
  </>
);

export default EmojiStaggered;
