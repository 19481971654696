import React, { useEffect, useState } from 'react';

class LRUCache {
  private cache: Map<string, string>;
  private maxSize: number;

  constructor(maxSize: number) {
    this.cache = new Map();
    this.maxSize = maxSize;
  }

  get(key: string): string | undefined {
    if (!this.cache.has(key)) return undefined;
    const value = this.cache.get(key);
    // Move to the end to show that it was recently used
    this.cache.delete(key);
    this.cache.set(key, value!);
    return value;
  }

  set(key: string, value: string) {
    // Remove the oldest element if the cache is at max capacity
    if (this.cache.size >= this.maxSize) {
      const oldestKey = this.cache.keys().next().value;
      this.cache.delete(oldestKey);
    }
    this.cache.set(key, value);
  }
}

export const emoji_cache = new LRUCache(65536);

const emoji_color_cache = new LRUCache(1024);

const getDominantColor = (emoji: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const cachedColor = emoji_color_cache.get(emoji);
    if (cachedColor) {
      resolve(cachedColor);
      return;
    }

    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100"><text y="1em" font-size="90">${emoji}</text></svg>`;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error('Canvas context not available'));
        return;
      }
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);

      const imageData = ctx.getImageData(0, 0, img.width, img.height);
      const data = imageData.data;
      const colorCount: { [key: string]: number } = {};
      let dominantColor = '';
      let maxCount = 0;

      for (let i = 0; i < data.length; i += 4) {
        const r = data[i];
        const g = data[i + 1];
        const b = data[i + 2];
        const a = data[i + 3];
        if (a < 255) continue; // Ignore transparent pixels
        if (r + g + b < 128) continue; // Ignore dark pixels

        const rgb = `${r},${g},${b}`;
        if (!colorCount[rgb]) colorCount[rgb] = 0;
        colorCount[rgb]++;

        if (colorCount[rgb] > maxCount) {
          maxCount = colorCount[rgb];
          dominantColor = rgb;
        }
      }

      const dominantColorString = `rgb(${dominantColor})`;
      emoji_color_cache.set(emoji, dominantColorString);
      resolve(dominantColorString);
    };

    img.onerror = (err) => {
      reject(err);
    };
  });
};

const EmojiText: React.FC<{ text: string, emoji: string }> = ({ text, emoji }) => {
  const [color, setColor] = useState<string>('');

  useEffect(() => {
    getDominantColor(emoji)
      .then((dominantColor) => {
        setColor(dominantColor);
      })
      .catch((err) => {
        console.error('Error extracting dominant color:', err);
      });
  }, [emoji]);

  return (
    <div style={{ color }}>
      {text}
    </div>
  );
};

export default EmojiText;
