type MenuItem = {
    id: string
    name: string
    emoji: string
    enabled: boolean
}

const menu_default_id = "title"

const menus: MenuItem[] = [
    {
        id: "/over-the-table",
        name: "Over-the-Table",
        emoji: "☕",
        enabled: true
    },
    {
        id: "/friends-multiplayer",
        name: "Play with Friends (WIP)",
        emoji: "👥",
        enabled: false
    },
    {
        id: "/true-multiplayer",
        name: "Multiplayer (WIP)",
        emoji: "🌍",
        enabled: false
    },
    {
        id: "/daily-challenge",
        name: "Daily Challenges (WIP)", // formerly Play against AI
        emoji: "✨",
        enabled: false
    },
    {
        id: `/info`,
        name: "About",
        emoji: "ℹ️",
        enabled: true
    },
    // {
    //     id: "show-anim",
    //     name: "DEBUG",
    //     emoji: "♾️",
    //     enabled: true
    // },
]; // HARDCODED max of 5 in Title.module.css

export { menus, menu_default_id };