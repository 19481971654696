// NewItemInput.tsx
import axios from 'axios';
import { doc, getDoc, getDocs, collection, setDoc } from "firebase/firestore"; 
import { db } from "./firebaseConfig";
import React, { useState, useRef, useImperativeHandle, forwardRef, useCallback } from 'react';
import { ItemRef } from './types'
import { emoji_cache } from './utils';
import { toast } from 'react-toastify'

interface NewItemInputProps {
  addItem: (name: string) => ItemRef;
  fillItem: (ref: ItemRef, emoji: string, name?: string) => void;
  handleSelection: (ref: ItemRef) => void;
  // confirmSelection: () => void;
}

export interface NewItemInputRef {
  isInputFocused: () => boolean;
}

export interface NewItemInputRef {
  isInputNonEmpty: () => boolean;
}

const NewItemInput = forwardRef<NewItemInputRef, NewItemInputProps>(({ addItem, fillItem, handleSelection }, ref) => {
  const [newItem, setNewItem] = useState('');
  const [randomState, setRandomState] = useState(0);  // SET THIS TO 2 TO DISABLE "I'm feeling lucky"
  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    isInputFocused: () => inputRef.current === document.activeElement,
    isInputNonEmpty: () => !!newItem.trim()
  }));

  const getRandomItem = useCallback(async () => {  // TODO: this is super inefficient, disable if necessary
    try {
      // Fetch all documents from the "emoji" collection
      const querySnapshot = await getDocs(collection(db, "emoji"));
  
      // Convert the documents to an array
      const emojiItemList = querySnapshot.docs.map(doc => ({
        name: doc.id, // Document ID can be used as the name
        emoji: doc.data().emoji, // Assuming the emoji field contains the emoji string
      }));
  
      // Select a random emoji from the list
      const randomIndex = Math.floor(Math.random() * emojiItemList.length);
      const randomEmojiItem = emojiItemList[randomIndex];
  
      return randomEmojiItem;
    } catch (error) {
      console.error("Error fetching emojis from Firestore: ", error);
      return null;
    }
  }, []);

  const handleAddItem = async () => {
    if (newItem.trim()) {
      setNewItem('');
      const newItemRef = addItem(newItem);
      handleSelection(newItemRef);
      const emoji = await getEmojiForItem(newItem);
      fillItem(newItemRef, emoji);
    } else if (!newItem.trim()) {
      
      // setNewItem('');
    }
  };

  const handleAddRandomItem = async () => {
    if (randomState > 0) {
      return
    }
    setRandomState(1);
    const newItemRef = addItem('...');
    const newItem = await getRandomItem()
    if (newItem) {
      const {name, emoji} = newItem
      fillItem(newItemRef, emoji, name.split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' '))
    } else {
      fillItem(newItemRef, '⚠️', 'Error')
    }
    setRandomState(0);
  }

  const getEmojiForItem = async (itemName: string): Promise<string> => {
    try {
      if (itemName.length > 30) {
        itemName = itemName.substring(0, 30) + '...'
      }
      const trimItemName = itemName.toUpperCase().trim();

      const cachedEmoji = emoji_cache.get(trimItemName);
      if (cachedEmoji) {
        return cachedEmoji;
      }

      // Reference to the document in the "emoji" collection
      const docRef = doc(db, "emoji", trimItemName);
      let docSnap = null;
      
      try {
        docSnap = await getDoc(docRef);
      } catch (error) {}
      
      if (docSnap?.exists()) {
        const emoji = docSnap.data().emoji
        // If it exists, return the emoji from Firestore
        emoji_cache.set(trimItemName, emoji);  
        return emoji;
      } else {
        // If it doesn't exist, make the API request

        const response = await axios.post(`/api/emojify`, {
          itemName
        }, {
          headers: {
            'Authorization': process.env.REACT_APP_AUTH_TOKEN
          }
        });
        
        const { item_name, emoji } = JSON.parse(response.data);

        emoji_cache.set(trimItemName, emoji);

        const setDocWithTimeout = new Promise<void>((resolve, reject) => {
          const timeout = setTimeout(() => {
            console.warn('setDoc operation timed out');
            resolve(); // Resolve to ignore timeout errors
          }, 5000);
  
          setDoc(doc(db, "emoji", trimItemName), { emoji })
            .then(() => {
              clearTimeout(timeout);
              resolve();
            })
            .catch((error) => {
              console.error('Error adding document:', error);
              resolve(); // Resolve to ignore errors
            });
        });

        setDocWithTimeout.catch((error) => console.error(error));

        return emoji;
      }
    } catch (error) {
      console.error('Error retrieving or adding item:', error);
      toast.error(`Error retrieving or adding item: ${error}`);
      return '⛔';
    }
  };
  
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleAddItem();
    }
  };

  return (
    <div className="add-item">
      <input
        ref={inputRef}
        type="text"
        value={newItem}
        onChange={(e) => setNewItem(e.target.value)}
        placeholder="Add new item"
        onKeyDown={handleKeyPress}
        className="add-item-input"
      />
      <button onClick={handleAddItem} disabled={!(newItem.trim())}>Add Item</button>
      {/* {randomState > 1 ? <></> : <button onClick={handleAddRandomItem} disabled={randomState > 0}>I'm Feeling Lucky</button>} */}
    </div>
  );
});

export default NewItemInput;
