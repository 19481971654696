import React, { useEffect, useState } from 'react'
  
const ascii_rps = {
    rock: `
      _______      
  ---'   ____)     
        (_____)    
        (_____)    
        (____)     
  ---.__(___)      
    `,
    paper: `
      _______       
  ---'   ____)____  
            ______) 
            _______)
           _______) 
  ---.__________)   
    `,
    scissors: `
      _______       
  ---'   ____)____  
            ______) 
         __________)
        (____)      
  ---.__(___)       
    `,
  };

  const pre_ascii_rps = {
    rock: `
ROCK,                 
    `,
    paper: `
      PAPER,          
    `,
    scissors: `
             SCISSORS,
    `,
    final: `
       SHOOT!         
    `
  };

  
  const RPS_ASCII: React.FC = () => {
    const [currentArt, setCurrentArt] = useState('');
    const [finalArt, setFinalArt] = useState<string | null>(null);
  
    useEffect(() => {
      if (finalArt) return;

      const options = ['rock', 'paper', 'scissors'] as const;
  
      
      let currentIndex = 0;
  
      const intervalId = setInterval(() => {
        setCurrentArt(pre_ascii_rps[options[currentIndex]] + ascii_rps[options[currentIndex]]);
        currentIndex = (currentIndex + 1) % options.length;
      }, 500);
  
      const timeoutId = setTimeout(() => {
        clearInterval(intervalId);
        setFinalArt(pre_ascii_rps.final + ascii_rps[options[Math.floor(Math.random() * 3)]]);
      }, 1995);
  
      return () => {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
      };
    }, [finalArt]);
  
    return (
      <pre style={{ whiteSpace: 'pre', fontFamily: 'monospace' }}>
        {finalArt || currentArt}
      </pre>
    );
  };

const NotFound: React.FC = () => {
    return <>
        <h1>
            Oops! We couldn't find that.
        </h1>

        <p>
            Here's a bunny rabbit playing rock paper scissors instead.
        </p>


        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ flex: 1, marginRight: '10px', textAlign: 'center' }}>
            <pre>{`
                     /\\    .-" /
                    /  ; .'  .' 
                   :   :/  .'   
                    \\  ;-.'
       .--""""--..__/     \`.
     .'           .'    \`o  \\
    /                    \`   ;
   :                  \\      :
 .-;        -.         \`.__.-'
:  ;          \\     ,   ;       
'._:           ;   :   (        
    \\/  .__    ;    \\   \`-.     
     ;     "-,/_..--"\`-..__)    
     '""--.._:`}
        </pre>
    </div>
        <div style={{ flex: 1, marginRight: '10px', textAlign: 'center' }}>
            {<RPS_ASCII/>}
        </div>
    </div>

        
    </>
}

export default NotFound;