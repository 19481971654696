import React, { useState, useRef } from 'react';
import ItemSelection from '../ItemSelection';
import BattleDisplay from '../BattleDisplay';
import NewItemInput, { NewItemInputRef } from '../NewItemInput';
import { Item, ItemRef } from '../types'

const DEFAULT_ITEMS = [
  { emoji: '🪨', name: 'Rock' },
  { emoji: '📄', name: 'Paper' },
  { emoji: '✂️', name: 'Scissors' }
]

interface OverTheTableProps {
  transitioning: boolean
  setTransitioning: (transitioning: boolean) => void
}

const OverTheTable: React.FC<OverTheTableProps> = ({ transitioning, setTransitioning }) => {
  const [itemsA, setItemsA] = useState<Item[]>(DEFAULT_ITEMS);
  const [itemsB, setItemsB] = useState<Item[]>(DEFAULT_ITEMS);
  const [userASelection, setUserASelection] = useState<ItemRef | null>(null);
  const [userBSelection, setUserBSelection] = useState<ItemRef | null>(null);
  const [currentUser, setCurrentUser] = useState<'A' | 'B'>('A');
  const [confirmedA, setConfirmedA] = useState(false);
  const [confirmedB, setConfirmedB] = useState(false);
  const newItemInputRef = useRef<NewItemInputRef>(null);

  const addItemA = (name: string) => {
    setItemsA([...itemsA, {
      emoji: null,
      name: name
    }]);
    return name;
  };

  const addItemB = (name: string) => {
    setItemsB([...itemsB, {
      emoji: null,
      name: name
    }]);
    return name;
  };

  const matchNameAndRef = (name: string, ref: ItemRef) => {
    return name === ref;
  }

  const fillItemA = (name: ItemRef, emoji: string, new_name?: string) => {
    setItemsA((prevItems) =>
      prevItems.map((item) =>
        matchNameAndRef(item.name, name) ? { name: new_name ?? item.name, emoji: emoji} : item
      )
    );
  };

  const fillItemB = (name: ItemRef, emoji: string, new_name?: string) => {
    setItemsB((prevItems) =>
      prevItems.map((item) =>
        matchNameAndRef(item.name, name) ? { name: new_name ?? item.name, emoji: emoji} : item
      )
    );
  };

  const handleSelection = (itemRef: ItemRef) => {
    if (currentUser === 'A') {
      if (userASelection === itemRef) {
        handleConfirm()
      } else {
        setUserASelection(itemRef)
      }
    } else {
      if (userBSelection === itemRef) {
        handleConfirm()
      } else {
        setUserBSelection(itemRef)
      }
    }
  };

  const handleConfirm = () => {
    if (newItemInputRef.current?.isInputFocused()) { //newItemInputRef.current.isInputNonEmpty()
      return; // NOTE: currently this does nothing
    }

    if (currentUser === 'A' && userASelection) {
      setTransitioning(true);
      setTimeout(() => {
        setCurrentUser('B');
        setConfirmedA(true);
        setTransitioning(false);
      }, 500);
    } else if (currentUser === 'B' && userBSelection) {
      setTransitioning(true);
      setTimeout(() => {
        setConfirmedB(true);
        setTransitioning(false);
      }, 250);
    }
  };

  const resetBattle = () => {
    setUserASelection(null);
    setUserBSelection(null);
    setCurrentUser('A');
    setConfirmedA(false);
    setConfirmedB(false);
  };

  return (
          <>
            {!confirmedA || !confirmedB ? (
              <>
                <ItemSelection
                  items={!confirmedA ? itemsA : itemsB}
                  handleSelection={handleSelection}
                  currentUser={currentUser}
                  userASelection={userASelection}
                  userBSelection={userBSelection}
                  transitioning={transitioning}
                  handleConfirm={handleConfirm}
                />
                <NewItemInput ref={newItemInputRef} addItem={!confirmedA ? addItemA : addItemB} fillItem={!confirmedA ? fillItemA : fillItemB} handleSelection={handleSelection} />
                <button
                  className="confirm-button"
                  onClick={handleConfirm}
                  disabled={(currentUser === 'A' && !userASelection) || (currentUser === 'B' && !userBSelection)}
                >
                  Confirm
                </button>
              </>
            ) : (
              <BattleDisplay
                userASelection={itemsA.find((item) => item.name === userASelection) ?? {name: "???", emoji: "🤔"}}
                userBSelection={itemsB.find((item) => item.name === userBSelection) ?? {name: "???", emoji: "🤔"}}
                resetBattle={resetBattle}
              />
            )}
          </>
  );
};

export default OverTheTable;
