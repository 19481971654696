import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import EmojiBg from './EmojiBg/EmojiBg';
import EmojiText from './utils';
import { Item } from './types'
import './BattleDisplay.css';

interface BattleDisplayProps {
  userASelection: Item;
  userBSelection: Item;
  resetBattle: () => void;
}

const BattleDisplay: React.FC<BattleDisplayProps> = ({ userASelection, userBSelection, resetBattle }) => {
  const [battleResult, setBattleResult] = useState<{ winner: string, emoji: string, onomatopoeia: string, description: string } | null>(null);
  const [animationState, setAnimationState] = useState(0);

  const suspensfulAnimate = useCallback(async () => {
    setTimeout(() => {
      setAnimationState(3)
    }, 900);
  }, []);

  const resetBattleAndAnim = () => {
    setAnimationState(0)
    resetBattle()
  }

  const fallbackDetermineWinner = useCallback(async (itemA: Item, itemB: Item) => {
    const fallbackHardcoded: Record<string, string> = {
      "scissors-paper": "Scissors cuts paper!",
      "paper-rock": "Paper covers rock!",
      "rock-scissors": "Rock crushes scissors!"
    };
  
    // Check if there's a hardcoded rule for this combination
    const combinationKey = `${itemA.name.toLowerCase()}-${itemB.name.toLowerCase()}`;
    const reverseCombinationKey = `${itemB.name.toLowerCase()}-${itemA.name.toLowerCase()}`;
    
    if (fallbackHardcoded[combinationKey]) {
      return {
        winner: itemA.name,
        emoji: itemA.emoji,
        onomatopoeia: "Pow!",
        description: fallbackHardcoded[combinationKey],
      };
    } else if (fallbackHardcoded[reverseCombinationKey]) {
      return {
        winner: itemB.name,
        emoji: itemB.emoji,
        onomatopoeia: "💥",
        description: fallbackHardcoded[reverseCombinationKey],
      };
    }

    const winner = Math.random() < 0.5 ? itemA : itemB;
    const loser = winner === itemA ? itemB : itemA;

    const fallbackOnomatopoeia = ["Bam!", "Boom!", "Crash!", "Zap!", "Pow!", "👊", "🥰", "💔", "💥", "[CENSORED]"];
    const fallbackDescription = [
      "{} beats {}! What a surprise!",
      "{} wins because it's just that awesome!",
      "{}🤜{}",
      "What were you expecting?",
      "{} does, uh, something to win. We don't really know what.",
      "You know what? {} wins. You don't need my explanation.",
      "I'm too lazy to come up with an explanation for why {} wins.",
      "{} wins because {} loses.",
      "It's not like anyone will ever read this, right?"
    ];

    const randomOnomatopoeia = fallbackOnomatopoeia[Math.floor(Math.random() * fallbackOnomatopoeia.length)];
    const randomDescriptionTemplate = fallbackDescription[Math.floor(Math.random() * fallbackDescription.length)];
    const description = (itemA === itemB) ? "What did you expect?" : randomDescriptionTemplate.replace('{}', winner.name).replace('{}', loser.name);

    return {
      winner: winner.name,
      emoji: winner.emoji,
      onomatopoeia: randomOnomatopoeia,
      description: description
    };
  }, []);

  const determineWinner = useCallback(async (itemA: Item, itemB: Item) => {
    try {
      const response = await axios.post(`/api/determine_winner`, {
        itemA,
        itemB
      }, {
        headers: {
          'Authorization': process.env.REACT_APP_AUTH_TOKEN
        }
      });
  
      const { winner, emoji, onomatopoeia, description } = JSON.parse(response.data);
      return { winner, emoji, onomatopoeia, description };
    } catch (error) {
      console.error('Error determining winner:', error);
      return fallbackDetermineWinner(itemA, itemB)
    }
  }, [fallbackDetermineWinner]);

  useEffect(() => {
    if (userASelection && userBSelection && (animationState === 0)) {
      setAnimationState(1);
      determineWinner(userASelection, userBSelection).then(result => {
        setBattleResult(result);
        setAnimationState(2);
        suspensfulAnimate();  
      });
    }
  }, [userASelection, userBSelection, animationState, determineWinner, suspensfulAnimate]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        resetBattle();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [resetBattle]);

  const renderOnomatopoeia = (text: string) => {
    const onomatopoeiaElements = Array.from({ length: 15 }, (_, index) => {
      const angle = Math.random() * 360;
      const distance = Math.random() * 50 + 20;
      const size = Math.random() * 2.5 + 1;
      const rotation = Math.random() * 360;
      const delay = Math.random() * 0.5; // Random delay for appearance
      const xOffset = Math.random() * 10 - 5;
      const yOffset = Math.random() * 10 - 5;

      return (
        <span
          key={index}
          className="onomatopoeia-text"
          style={{
            transform: `translate(${xOffset}px, ${yOffset}px) rotate(${rotation}deg) scale(${size}) translate(${distance}px) rotate(${angle}deg)`,
            animationDelay: `${delay}s`,
          }}
        >
          {text}
        </span>
      );
    });
    return <div className="onomatopoeia-container">{onomatopoeiaElements}</div>;
  };

  return (
    <div className="battle-display">
      {userASelection && userBSelection ? ( userASelection.emoji && userBSelection.emoji ? (
        (animationState === 1) ? (
          <div className="battle-animation">
              <EmojiText text={userASelection.emoji} emoji={userASelection.emoji}/>
              <span className="vs vs-animate">vs</span>
              <EmojiText text={userBSelection.emoji} emoji={userBSelection.emoji}/>
            
          </div>
        ) : (animationState === 2) ? (
          <>
          <div className="battle-animation">
            
            {battleResult?.onomatopoeia && renderOnomatopoeia(battleResult.onomatopoeia)}
          </div>
          <div className="battle-animation-fadeout">
              <EmojiText text={userASelection.emoji} emoji={userASelection.emoji}/>
              <span className="vs vs-animate">vs</span>
              <EmojiText text={userBSelection.emoji} emoji={userBSelection.emoji}/>
          </div>
          <div className="battle-animation">
            
            {battleResult?.onomatopoeia && renderOnomatopoeia(battleResult.onomatopoeia)}
          </div>
          </>
        ) : battleResult ? (
          <>
            <EmojiBg emoji={battleResult.emoji}/>
            <div>
              <div className="battle">
                <div className="battle-side-left"><EmojiText text={`${userASelection.emoji} ${userASelection.name}`} emoji={userASelection.emoji}/></div>
                <div className="vs-container">
                  <span className="vs">vs</span>
                </div>
                <div className="battle-side-right"><EmojiText text={`${userBSelection.emoji} ${userBSelection.name}`} emoji={userBSelection.emoji}/></div>
              </div>
              <span className="winner-title">
                {battleResult.winner === "Tie" ? "TIE" : "WINNER"}
              </span>
              <div className="winner">
                <strong>
                  <EmojiText text={`${battleResult.emoji} ${battleResult.winner} ${battleResult.emoji}`} emoji={battleResult.emoji}/>
                </strong>
                {/* {renderOnomatopoeia(battleResult.emoji)} */}
              </div>
              <div className="description">
                {battleResult.description}
              </div>
              <div className="button-container">
                <button className="next-button" onClick={resetBattleAndAnim}>Next Battle</button>
              </div>
            </div>
          </>
        ) : null
      ) : (
        <div>Waiting for emojis to load . . . </div>
      )

      ) : (
        <div>Waiting for selection to load . . . </div>
        // Should be an unreachable state
      )}
    </div>
  );
};

export default BattleDisplay;
