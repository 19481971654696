import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
// import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyAVPPiuRPgTOLpzjtDUqj9S6oyvlqwQyiM",
  authDomain: "rock-paper-gpt.firebaseapp.com",
  projectId: "rock-paper-gpt",
  storageBucket: "rock-paper-gpt.appspot.com",
  messagingSenderId: "934780203009",
  appId: "1:934780203009:web:5e8e509206f41f2c185000",
  measurementId: "G-E9N0QXD95V"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
// const analytics = getAnalytics(app);

export {db};
