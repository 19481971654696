import React from 'react';
import './EmojiGrid.css';

type Props = { emoji: string };

const EmojiGrid: React.FC<Props> = ({ emoji }) => (
  <div className="grid-container">
    {Array.from({ length: 60 }).map((_, index) => (
      <span key={index} className="grid-emoji">{emoji}</span>
    ))}
  </div>
);

export default EmojiGrid;
