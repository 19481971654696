import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Sidebar.css';
import { menus } from './constants'

const Sidebar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 600);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <button className="hamburger" onClick={toggleSidebar}>
        ☰
      </button>
      <div className={`menu ${isMobile && !isOpen ? 'hidden' : ''}`}>
      {isOpen ? (
        <>
          {menus.map((menu) => (
            <button
              key={menu.id}
              onClick={() => {navigate(menu.id); toggleSidebar()}}
              disabled={!menu.enabled}
            >
              {menu.name} {menu.emoji}
            </button>
          ))}
        </>
      ) : (
        <>
          {menus.map((menu) => (
            <button
              key={menu.id}
              onClick={() => {navigate(menu.id)}}
              disabled={!menu.enabled}
            >
              {menu.emoji}
            </button>
          ))}
        </>
      )}
      </div>
    </div>
  );
};

export default Sidebar;
