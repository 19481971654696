import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import OverTheTable from './OverTheTable/OverTheTable';
import InfoBox from './InfoBox';
import NotFound from './NotFound'
import Sidebar from './Sidebar';
import Title from './Title';
import './App.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <ToastContainer/>
        <AppContent/>
      </div>
    </Router>
  );
};

const AppContent: React.FC = () => {
  const [transitioning, setTransitioning] = useState(false);
  const location = useLocation();

  return (
    <>
      {location.pathname !== '/' && <Sidebar />}
      
      <div className={`main-content ${transitioning ? 'fade-out' : 'fade-in'}`}>
        <Routes>
          <Route path="/" element={<Title />} />
          <Route path="/over-the-table" element={<OverTheTable transitioning={transitioning} setTransitioning={setTransitioning} />} />
          <Route path="/info" element={<InfoBox transitioning={transitioning} setTransitioning={setTransitioning} />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      
      {(location.pathname === '/') &&
      <div className="credits">
        <a href="https://github.com/user04f8/" target="_blank" rel="noopener noreferrer">
          Created by Nathan Clark
        </a>
      </div>
      }
    </>
  );
};

export default App;
